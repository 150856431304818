import React, { createContext, useContext, useEffect, useState } from "react";
import { CachedUser, CachedUserDetails } from "../utils/UserData";
import { fetchUserDetails } from "../HTTPS/ApiService";

const UserContext = createContext();

export const useUser = () => {
  return useContext(UserContext);
};

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState({
    name: CachedUser()?.name,
    role: CachedUser()?.role
  });
  const [userDetails, setUserDetails] = useState({
    fname: CachedUserDetails()?.fname,
    mname: CachedUserDetails()?.mname,
    lname: CachedUserDetails()?.lname,
    mobile: CachedUserDetails()?.mobile,
    address1: CachedUserDetails()?.address1,
    address2: CachedUserDetails()?.address2,
    city: CachedUserDetails()?.city,
    state: CachedUserDetails()?.state,
    zipcode: CachedUserDetails()?.zipcode,
    country: CachedUserDetails()?.country,
    relationship: CachedUserDetails()?.relationship,
  });

  useEffect(() => {
    if (CachedUser()?.accessToken) {
      (async () => {
        try {
          // Assuming fetchRelationships is an asynchronous function that returns the relationship data
          const response = await fetchUserDetails();
          setUserDetails(response);
        } catch (error) {
          console.error("Error fetching relationships:", error);
        }
      })();
    }
  }, []);

  return (
    <UserContext.Provider
      value={{ user, setUser, userDetails, setUserDetails }}
    >
      {children}
    </UserContext.Provider>
  );
};
