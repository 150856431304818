import ReportWrapper from '../ReportWrapper'
import ReportTable from './ReportTable';

function ReportTableWrapper({ data, print = false }) {
  return (
    <ReportWrapper data={data} >
      <ReportTable data={data} print={print} />
    </ReportWrapper>
  );
}

export default ReportTableWrapper;
