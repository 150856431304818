/* eslint-disable eqeqeq */
import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useUser } from "../context/UserContext";
import { usePatient } from "../context/PatientContext";

import {
  loginUser,
  fetchUserDetails,
  fetchCommunity,
  fetchPatientList,
} from "../HTTPS/ApiService";
import axios from "axios";
import { useGoogleLogin } from "@react-oauth/google";
import { googleLoginEndpoint } from "../HTTPS/Api";
import MessengerService from "../servies/MessengerService";

const SignIn = () => {
  const navigate = useNavigate();
  const emailRef = useRef();
  const passwordRef = useRef();
  const [errorMessage, setErrorMessage] = useState("");
  const [noPatientErrorMessage, setNoPatientErrorMessage] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { setUser, setUserDetails } = useUser();
  const { setPatient, setPatientList } = usePatient();

  useEffect(() => {
    const hasSignedIn = localStorage.getItem("hasSignedIn");
    if (hasSignedIn) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const afterLogin = async (data) => {
    if (data) {

      //divide by 1000 to convert to seconds
      const now = Math.floor(Date.now() / 1000);

      const loginInfo = {
        username: data.UserName,
        name: data.Name,
        role: data.Role,
        accessToken: data.AccessToken,
        refreshToken: data.RefreshToken,
        forcePwdChange: data.ForcePwdChange,
        expiry: now + data.Expires, //Expires in seconds
      };

      setUser(loginInfo);

      // Update user context and local storage
      localStorage.setItem("user", JSON.stringify(loginInfo));

      const userDetails = await fetchUserDetails();

      setUserDetails(userDetails);

      // Store user data in local storage
      localStorage.setItem("userDetails", JSON.stringify(userDetails));

      try {
        const list = await fetchPatientData();

        if (list.length === 0) {
          setNoPatientErrorMessage(true);
          setErrorMessage("");
          console.log(list.length);
          return null;
        } else {
          // Redirect after successful sign-in
          localStorage.setItem("hasSignedIn", "true");
          if (loginInfo.forcePwdChange) {
            navigate("/reset/password");
          } else {
            navigate("/");
          }
          // window.location.reload(true);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const fetchPatientData = async () => {
    let list = [];
    try {
      list = await fetchPatientList();
      if (list && list.length > 0) {
        // Check if patient is stored in localStorage, if yes, set that patient
        const storedPatient = localStorage.getItem("selectedPatient");
        if (storedPatient) {
          setPatient(JSON.parse(storedPatient));
        } else {
          // Set the details of the first patient as the default patient
          MessengerService.setSelectedPatient(list[0].id);
          setPatient(list[0]);
          localStorage.setItem("selectedPatient", JSON.stringify(list[0]));
        }
      }
      setPatientList(list);
      const selectedPatient = JSON.parse(
        localStorage.getItem("selectedPatient")
      );
      if (selectedPatient?.id) {
        fetchCommunity(selectedPatient?.id).then((data) => {
          localStorage.setItem("communityMembers", JSON.stringify(data));
        });
      }
    } catch (error) {
      console.error("Error fetching patient details:", error);
    }
    return list;
  };

  // Handle the sign-in process
  const handleSignIn = async (e) => {
    e.preventDefault();
    // Get entered email and password
    const enteredEmail = emailRef.current.value.toLowerCase();
    const enteredPassword = passwordRef.current.value.toLowerCase();

    try {
      // Login user and fetch details
      const data = await loginUser(enteredEmail, enteredPassword);
      await afterLogin(data);
    } catch (error) {
      // Handle sign-in errors
      console.error("Error while signing in:", error);
      setErrorMessage("The username or password is Invalid!");
    }
  };

  const LoginWithGoogle = useGoogleLogin({
    onFailure: (r) => { },
    onSuccess: async (codeResponse) => {
      // console.log(codeResponse);
      var token = codeResponse.access_token;
      try {
        const response = await axios.post(googleLoginEndpoint, token, {
          headers: { "Content-Type": "application/json" },
        });

        if (response.status == 200) {
          await afterLogin(response.data);
        } else {
          setErrorMessage(response.data);
        }
      } catch (error) {
        setErrorMessage(error.response.data);
      }
    },
    onError: (errorResponse) => console.log(errorResponse),
  });

  const enableEmailSignIn = process.env.REACT_APP_MK_FRONT_API;
  const devURL = "https://mkdevfrontapi.mirakare.com"
  const stageURL = "https://stagefrontapi.azurewebsites.net"
  const isDevOrStage = enableEmailSignIn === devURL || enableEmailSignIn === stageURL

  return (
    <div className="cover">
      <div className="mx-auto px-4 py-16 sm:px-6 lg:px-8 flex h-screen items-center">
        <div className="mx-auto w-full sm:w-1/2 xl:w-1/3">
          <Link
            className="flex flex-shrink-0 items-center justify-center mx-auto"
            to="/"
          >
            <img src="/images/logo/logo.png" alt="Logo" className="h-20 w-48" />
          </Link>

          <form
            onSubmit={handleSignIn}
            className="mb-0 mt-6 space-y-4 rounded-lg p-4 shadow-lg sm:p-6 lg:p-8 bg-white"
          >
            <p className="text-center text-[#1d3e7d] text-4xl font-bold">
              Sign in here
            </p>

            {isDevOrStage && (
              <div className="">
                <div>
                  <label htmlFor="email" className="sr-only">
                    Email
                  </label>

                  <div className="relative">
                    <input
                      type="email"
                      ref={emailRef}
                      className="w-full rounded-lg border-gray-200 p-4 pe-12 text-sm shadow-sm"
                      placeholder="Enter email"
                      required
                    />
                  </div>
                </div>

                <div>
                  <label htmlFor="password" className="sr-only">
                    Password
                  </label>

                  <div className="relative">
                    <input
                      type={showPassword ? "text" : "password"}
                      ref={passwordRef}
                      id="password"
                      className="w-full rounded-lg border-gray-200 p-4 pe-12 text-sm shadow-sm"
                      placeholder="Enter password"
                      required
                    />
                    <button
                      type="button"
                      className="absolute inset-y-0 end-0 grid place-content-center px-4"
                      onClick={(e) => {
                        e.preventDefault();
                        setShowPassword(!showPassword);
                      }}
                    >
                      {showPassword ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M15.58 12c0 1.98-1.6 3.58-3.58 3.58S8.42 13.98 8.42 12s1.6-3.58 3.58-3.58 3.58 1.6 3.58 3.58Z"
                            stroke="#101010"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                          <path
                            d="M12 20.27c3.53 0 6.82-2.08 9.11-5.68.9-1.41.9-3.78 0-5.19-2.29-3.6-5.58-5.68-9.11-5.68-3.53 0-6.82 2.08-9.11 5.68-.9 1.41-.9 3.78 0 5.19 2.29 3.6 5.58 5.68 9.11 5.68Z"
                            stroke="#101010"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="m14.53 9.47-5.06 5.06a3.576 3.576 0 1 1 5.06-5.06Z"
                            stroke="#101010"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                          <path
                            d="M17.82 5.77C16.07 4.45 14.07 3.73 12 3.73c-3.53 0-6.82 2.08-9.11 5.68-.9 1.41-.9 3.78 0 5.19.79 1.24 1.71 2.31 2.71 3.17M8.42 19.53c1.14.48 2.35.74 3.58.74 3.53 0 6.82-2.08 9.11-5.68.9-1.41.9-3.78 0-5.19-.33-.52-.69-1.01-1.06-1.47"
                            stroke="#101010"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                          <path
                            d="M15.51 12.7a3.565 3.565 0 0 1-2.82 2.82M9.47 14.53 2 22M22 2l-7.47 7.47"
                            stroke="#101010"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                        </svg>
                      )}
                    </button>
                  </div>
                  {errorMessage && (
                    <p className="text-red-500 px-1">{errorMessage}</p>
                  )}
                </div>

                <button
                  type="submit"
                  className="block w-full rounded-lg bg-indigo-600 hover:bg-indigo-700 active-bg-indigo-800 px-5 py-3 text-sm font-medium text-white"
                >
                  Sign in
                </button>
              </div>
            )}

            {!isDevOrStage && errorMessage && (
              <p className="text-red-500 px-1">{errorMessage}</p>
            )}
            <button
              type="button"
              onClick={() => LoginWithGoogle()}
              className="flex items-center bg-white border border-gray-300 rounded-lg shadow-md px-6 py-2 text-sm font-medium text-gray-800  hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 w-full justify-center"
            >
              <svg
                className="h-6 w-6 mr-2"
                xmlns="http://www.w3.org/2000/svg"
                width="800px"
                height="800px"
                viewBox="-0.5 0 48 48"
                version="1.1"
              >
                <g
                  id="Icons"
                  stroke="none"
                  strokeWidth="1"
                  fill="none"
                  fillRule="evenodd"
                >
                  {" "}
                  <g
                    id="Color-"
                    transform="translate(-401.000000, -860.000000)"
                  >
                    {" "}
                    <g
                      id="Google"
                      transform="translate(401.000000, 860.000000)"
                    >
                      {" "}
                      <path
                        d="M9.82727273,24 C9.82727273,22.4757333 10.0804318,21.0144 10.5322727,19.6437333 L2.62345455,13.6042667 C1.08206818,16.7338667 0.213636364,20.2602667 0.213636364,24 C0.213636364,27.7365333 1.081,31.2608 2.62025,34.3882667 L10.5247955,28.3370667 C10.0772273,26.9728 9.82727273,25.5168 9.82727273,24"
                        id="Fill-1"
                        fill="#FBBC05"
                      >
                        {" "}
                      </path>{" "}
                      <path
                        d="M23.7136364,10.1333333 C27.025,10.1333333 30.0159091,11.3066667 32.3659091,13.2266667 L39.2022727,6.4 C35.0363636,2.77333333 29.6954545,0.533333333 23.7136364,0.533333333 C14.4268636,0.533333333 6.44540909,5.84426667 2.62345455,13.6042667 L10.5322727,19.6437333 C12.3545909,14.112 17.5491591,10.1333333 23.7136364,10.1333333"
                        id="Fill-2"
                        fill="#EB4335"
                      >
                        {" "}
                      </path>{" "}
                      <path
                        d="M23.7136364,37.8666667 C17.5491591,37.8666667 12.3545909,33.888 10.5322727,28.3562667 L2.62345455,34.3946667 C6.44540909,42.1557333 14.4268636,47.4666667 23.7136364,47.4666667 C29.4455,47.4666667 34.9177955,45.4314667 39.0249545,41.6181333 L31.5177727,35.8144 C29.3995682,37.1488 26.7323182,37.8666667 23.7136364,37.8666667"
                        id="Fill-3"
                        fill="#34A853"
                      >
                        {" "}
                      </path>{" "}
                      <path
                        d="M46.1454545,24 C46.1454545,22.6133333 45.9318182,21.12 45.6113636,19.7333333 L23.7136364,19.7333333 L23.7136364,28.8 L36.3181818,28.8 C35.6879545,31.8912 33.9724545,34.2677333 31.5177727,35.8144 L39.0249545,41.6181333 C43.3393409,37.6138667 46.1454545,31.6490667 46.1454545,24"
                        id="Fill-4"
                        fill="#4285F4"
                      >
                        {" "}
                      </path>{" "}
                    </g>{" "}
                  </g>{" "}
                </g>{" "}
              </svg>
              <span>Continue with Google</span>
            </button>

            {/* <Link
              to="/reset/password"
              className="flex items-end justify-end text-end text-indigo-700 text-sm"
            >
              Forgot Password ?
            </Link> */}
          </form>

          {noPatientErrorMessage && (
            <div className="mt-3 text-white text-center justify-center items-center">
              You do not have a Care Recipient Assigned to your account as yet.
              To use this App further you need to have Recipients assigned.
              Kindly contact the administrator and try again later!
              {/* <div className="flex justify-center items-center w-full">
                <button
                  className="block w-full rounded-lg bg-indigo-600 hover:bg-indigo-700 active-bg-indigo-800 px-5 py-2 mt-2 text-md font-medium text-white"
                  onClick={() => window.location.reload(true)}
                >
                  OK
                </button>
              </div> */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SignIn;
